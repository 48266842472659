import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Space, Table, Row, Col, Select, Tabs, Checkbox } from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {PageServiceService, ProductService, StrategyService} from 'services'
import { get } from 'lodash'
import QuillEditor from 'Components/QuillEditor'
import CustomTree from './CustomTree' // Adjust the import path as needed
import { useNavigate } from 'react-router-dom'
import { value } from 'lodash/seq'
import { OptionValue } from 'utils'

const { Option } = Select
const { TabPane } = Tabs
const CheckboxGroup = Checkbox.Group
const timing_option = OptionValue.timing_option
const action_by_option = OptionValue.action_by_option
const advise_for_options_all = OptionValue.advise_for_options_all

export default function AdviceStrategy({ preview, business_list }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [area_list, setAreaList] = useState([])
  const [attribute_list, setAttributeList] = useState([])
  const [benefit_risk_list, setBenefitRiskList] = useState([])
  const [implementation_steps_list, seImplementationStepsList] = useState([])
  const [benefits, setBenefits] = useState([])
  const [advice_implementation_steps, setAdviceImplementationSteps] = useState([])
  const [risks, setRisks] = useState([])
  const [webLinks, setWebLinks] = useState([])
  const [videoLinks, setVideoLinks] = useState([])
  const [permissions, setPermissions] = useState({})
  const [activeKey, setActiveKey] = useState('1')
  const [asset_type_option, setAssetSubtypeOption] = useState({})
  const [liability_type_option, setLiabilitySubtypeOption] = useState({})
  const [product_type_list, setProductTypeList] = useState([])
  const navigate = useNavigate()

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  let business = null
  if (entity_type == 'business') {
    business = params.get('entity')
  }

  const getProductTypeList = async () => {
    const res = await ProductService.getProductType({ business: business })
    setProductTypeList(get(res, 'data.data', []))
  }

  const get_field_option = (netwealth_type, key) => {

    const data_config = {
    type_id: netwealth_type == "asset" ? "1272529a-27b0-4482-8e9d-970fde5258fa" : "6fadc5ea-095f-48a7-ad1f-2aa5a84432d5",
    subtype_id: netwealth_type == "asset" ? "9021be88-1c47-4ca2-88db-db88bbff227f" : "f53af184-becd-40b5-b12d-b6c60077c287",
    }
    return data_config[key]

  }

  const getAssetSubType = async (netwealth_type,  key) => {
      const res = await PageServiceService.FieldDetail(get_field_option(netwealth_type, key))
      setAssetSubtypeOption(get(res, 'data.data', {}))
  }

  const getLiabilitySubType = async (netwealth_type,  key) => {
      const res = await PageServiceService.FieldDetail(get_field_option(netwealth_type, key))
      setLiabilitySubtypeOption(get(res, 'data.data', {}))
  }

  useEffect(() => {
    getData()
    getAssetSubType('asset', 'subtype_id')
    getLiabilitySubType('liability', 'subtype_id')
  }, [])

  const columns = [
    {
      title: 'Area',
      dataIndex: 'area',
      key: 'area',
      sorter: (a, b) => {
        if (a.area && b.area) {
          return a.area.subject.localeCompare(b.area.subject)
        }
        return 0
      },
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Area</h6>
          {record.area ? record.area.subject : ''}
        </div>
      ),
    },
    {
      title: 'Attribute',
      dataIndex: 'attribute',
      key: 'attribute',
      sorter: (a, b) => {
        if (a.attribute && b.attribute) {
          return a.attribute.subject.localeCompare(b.attribute.subject)
        }
        return 0
      },
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Attribute</h6>
          {record.attribute ? record.attribute.subject : ''}
        </div>
      ),
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Subject</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} size="medium" onClick={() => getDetail(record.id)} />
          <Button
            icon={<QuestionCircleOutlined />}
            size={'medium'}
            onClick={() => {
              navigate(`/admin/conditions?advice_strategy=${record.id}&fieldId=${record.id}`, {
                push: true,
              })
            }}
          />
          {!record.parent && (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => deleteData(record.id)}
            />
          )}
        </Space>
      ),
    },
  ]

  // Conditionally add the override  2nd last column if business is present
  if (business) {
    const secondLastIndex = columns.length - 1
    columns.splice(secondLastIndex, 0, {
      title: 'Override',
      dataIndex: 'is_override',
      key: 'is_override',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Override</h6>
          {record.is_override ? 'Yes' : 'No'}
        </div>
      ),
    })
  }

  const setInitialValue = () => {
    formik.resetForm()
    formik.setValues(initialValues)
    setId(null)
    setWebLinks([])
    setVideoLinks([])
    setBenefits([])
    setRisks([])
    setAttributeList([])
    setAdviceImplementationSteps([])
    setBenefitRiskList([])
    setPermissions({})
    setActiveKey('1')
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInitialValue()
    getAreaList()
    getBenefitRiskList()
    getImplementationStepsList()
    getProductTypeList()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const getDetail = async (id) => {
    getAreaList()
    getBenefitRiskList()
    getImplementationStepsList()
    getProductTypeList()
    await setId(id)
    await setIsModalVisible(true)
    const res = await StrategyService.getDetailAdviceStrategy(id)
    getAttributeList(get(res, 'data.data.area'))
    formik.setValues(get(res, 'data.data'))
    setWebLinks(get(res, 'data.data.web_links', []))
    setVideoLinks(get(res, 'data.data.video_links', []))
    setBenefits(get(res, 'data.data.benefits', []))
    setAdviceImplementationSteps(get(res, 'data.data.advice_implementation_steps', []))

    setRisks(get(res, 'data.data.risks', []))
    setPermissions(get(res, 'data.data.permissions', {}))
  }

  const deleteData = async (id) => {
    const result = window.confirm('Are you sure to delete this attribute?')
    if (result) {
      await StrategyService.deleteAdviceStrategy(id)
      getData()
    }
  }

  const getData = async () => {
    const res = await StrategyService.getAdviceStrategy({ business: business })
    setData(get(res, 'data.data', []))
  }

  const getAreaList = async () => {
    const res = await StrategyService.getStrategyArea({ business: business })
    setAreaList(get(res, 'data.data', []))
  }

  const getAttributeList = async (area) => {
    const res = await StrategyService.getStrategyAttribute({
      area: area,
      business: business,
    })
    setAttributeList(get(res, 'data.data', []))
  }

  const getBenefitRiskList = async () => {
    const res = await StrategyService.getBenefitRisk()
    setBenefitRiskList(get(res, 'data.data', []))
  }

  const getImplementationStepsList = async () => {
    const res = await StrategyService.getImplementationStep()
    seImplementationStepsList(get(res, 'data.data', []))
  }

  let schema = yup.object().shape({
    area: yup.string().required('Area Required'),
    attribute: yup.string().required('Attribute Required'),
    subject: yup.string().required('Subject Required'),
    advice_for: yup.array(),
    description: yup.string(),
    intro: yup.string(),
    body_text: yup.string(),
    summary: yup.string(),
    pros: yup.string(),
    cons: yup.string(),
    web_links: yup.array().of(
      yup.object().shape({
        web_link: yup.string().url('Invalid URL').required('Web link required'),
        web_name: yup.string().required('Web name required'),
      })
    ),
    video_links: yup.array().of(
      yup.object().shape({
        video_icon: yup.string().required('Video icon required'),
        video_link: yup.string().url('Invalid URL').required('Video link required'),
      })
    ),
    benefits: yup.array().of(
      yup.object().shape({
        benefit: yup.string().required('benefit required'),
        required: yup.string().required('required'),
      })
    ),
    advice_implementation_steps: yup.array().of(
      yup.object().shape({
        implementation_step: yup.string().required('benefit required'),
        action_by: yup.string(),
        timing: yup.string(),
      })
    ),
    risks: yup.array().of(
      yup.object().shape({
        risk: yup.string().required('risk required'),
        required: yup.string().required('required'),
      })
    ),
  })

  const initialValues = {
    subject: '',
    description: '',
    intro: '',
    body_text: '',
    summary: '',
    area: '',
    attribute: '',
    pros: '',
    cons: '',
    web_links: [],
    video_links: [],
    benefits: [],
    advice_implementation_steps: [],
    risks: [],
    permissions: {},
    advice_for: [],
    asset_movement_current: false,
    asset_movement_proposed: false,
    asset_subtype: {},
    liability_subtype: {},
    liability_movement_current: false,
    liability_movement_proposed: false,
    show_analysis:false,
    life_insurance:false,
    general_insurance: false,
    health_insurance:false,
    liability_product_type: "",
    asset_product_type: ""
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      values['business'] = business
      values['permissions'] = permissions
      if (id) {
        if (business) {
          const result = window.confirm('Are you sure to override this advice strategy?')
          if (result) {
            await StrategyService.updateAdviceStrategy(values, id)
          }
        } else {
          await StrategyService.updateAdviceStrategy(values, id)
        }
      } else {
        await StrategyService.saveAdviceStrategy(values)
      }
      setIsModalVisible(false)
      getData()
      setInitialValue()
    },
    validationSchema: schema,
  })

  const onChangeArea = (value) => {
    if (value) {
      getAttributeList(value)
    } else {
      setAttributeList([])
    }
    formik.setFieldValue('area', value)
    formik.setFieldValue('attribute', '')
  }

  const onCheckPerMission = (checkedKeys) => {
    // console.log(checkedKeys);
    setPermissions(checkedKeys)
  }

  const handleReset = async () => {
    await setIsModalVisible(true)
    const result = window.confirm('Are you sure to reset this advice strategy to global?')
    if (result) {
      const res = await StrategyService.getResetAdviceStrategy(id)
      formik.setValues(get(res, 'data.data'))
      setWebLinks(get(res, 'data.data.web_links', []))
      setVideoLinks(get(res, 'data.data.video_links', []))
      setBenefits(get(res, 'data.data.benefits', []))
      setAdviceImplementationSteps(get(res, 'data.data.advice_implementation_steps', []))
      setRisks(get(res, 'data.data.risks', []))
    }
  }

  const handleAddWebLink = () => {
    setWebLinks([...webLinks, { web_link: '', web_name: '' }])
  }

  const handleAddBenefit = () => {
    setBenefits([...benefits, { benefit: '', required: 'no' }])
  }

  const handleAddImplementationSteps = () => {
    setAdviceImplementationSteps([
      ...advice_implementation_steps,
      { action_by: '', timing: '', required: 'no' },
    ])
  }

  const handleAddRisk = () => {
    setRisks([...risks, { risk: '', required: 'no' }])
  }

  const handleAddVideoLink = () => {
    setVideoLinks([...videoLinks, { video_icon: '', video_link: '' }])
  }

  const handleWebLinkChange = (index, event) => {
    const { name, value } = event.target
    const newWebLinks = webLinks.slice()
    newWebLinks[index][name] = value
    setWebLinks(newWebLinks)
    formik.setFieldValue('web_links', newWebLinks)
  }

  const handleBenefitChange = (index, name, value) => {
    const newBenefits = benefits.slice()
    newBenefits[index][name] = value
    setBenefits(newBenefits)
    formik.setFieldValue('benefits', newBenefits)
  }

  const handleAdviceImplementationStepsChange = (index, name, value) => {
    const new_advice_implementation_steps = advice_implementation_steps.slice()
    advice_implementation_steps[index][name] = value
    setAdviceImplementationSteps(new_advice_implementation_steps)
    formik.setFieldValue('advice_implementation_steps', new_advice_implementation_steps)
  }

  const handleRiskChange = (index, name, value) => {
    const newRisks = risks.slice()
    newRisks[index][name] = value
    setRisks(newRisks)
    formik.setFieldValue('risks', newRisks)
  }

  const handleVideoLinkChange = (index, event) => {
    const { name, value } = event.target
    const newVideoLinks = videoLinks.slice()
    newVideoLinks[index][name] = value
    setVideoLinks(newVideoLinks)
    formik.setFieldValue('video_links', newVideoLinks)
  }

  const handleRemoveWebLink = (index) => {
    const newWebLinks = webLinks.slice()
    newWebLinks.splice(index, 1)
    setWebLinks(newWebLinks)
    formik.setFieldValue('web_links', newWebLinks)
  }

  const handleRemoveVideoLink = (index) => {
    const newVideoLinks = videoLinks.slice()
    newVideoLinks.splice(index, 1)
    setVideoLinks(newVideoLinks)
    formik.setFieldValue('video_links', newVideoLinks)
  }

  const handleRemoveBenefit = (index) => {
    const newBenefits = benefits.slice()
    newBenefits.splice(index, 1)
    setBenefits(newBenefits)
    formik.setFieldValue('benefits', newBenefits)
  }

  const handleRemoveImplementationSteps = (index) => {
    const new_advice_implementation_steps = advice_implementation_steps.slice()
    new_advice_implementation_steps.splice(index, 1)
    setAdviceImplementationSteps(new_advice_implementation_steps)
    formik.setFieldValue('advice_implementation_steps', new_advice_implementation_steps)
  }

  const handleRemoveRisk = (index) => {
    const newRisks = risks.slice()
    newRisks.splice(index, 1)
    setRisks(newRisks)
    formik.setFieldValue('risks', newRisks)
  }

  return (
    <div>
      <h1 className="flex_title">
        <span className="title"></span>
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          Add Strategy
        </Button>
      </h1>
      <Table
        className="custom_responsive_table"
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
      />
      <Modal
        title={id ? 'Edit Strategy' : 'Add Strategy'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'100%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          business && id && formik.values.is_override && (
            <Button key="reset" type="primary" onClick={handleReset}>
              Reset
            </Button>
          ),
          <Button key="submit" type="primary" onClick={formik.handleSubmit}>
            Save
          </Button>,
        ]}
      >
        <Tabs type="card" activeKey={activeKey} onChange={setActiveKey}>
          <TabPane tab="Main" key="1">
            <Form layout="vertical" className={'custom_modal_form'}>
              <Form.Item label="Area">
                <Select
                  mode="single"
                  name="area"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select area"
                  value={formik.values.area}
                  onChange={onChangeArea}
                >
                  <Option value=""></Option>
                  {area_list.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.subject}
                    </Option>
                  ))}
                </Select>
                {formik.touched.area && formik.errors.area && (
                  <span className="input-error-message">{formik.errors.area}</span>
                )}
              </Form.Item>
              <Form.Item label="Attribute">
                <Select
                  mode="single"
                  name="attribute"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select attribute"
                  value={formik.values.attribute}
                  onChange={(value) => formik.setFieldValue('attribute', value)}
                >
                  {attribute_list.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.subject}
                    </Option>
                  ))}
                </Select>
                {formik.touched.attribute && formik.errors.attribute && (
                  <span className="input-error-message">{formik.errors.attribute}</span>
                )}
              </Form.Item>
              <Form.Item label="Subject">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Subject"
                  name="subject"
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                />
                {formik.touched.subject && formik.errors.subject && (
                  <span className="input-error-message">{formik.errors.subject}</span>
                )}
              </Form.Item>
              <Form.Item label="Advice for">
                <Select
                  mode="multiple"
                  name="advice_for"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select advice_for"
                  value={formik.values.advice_for}
                  onChange={(value) => formik.setFieldValue('advice_for', value)}
                >
                  {Object.entries(advise_for_options_all).map(([key, value]) => (
                    <Option key={key} value={key}>
                      {value}
                    </Option>
                  ))}
                </Select>
                {formik.touched.advice_for && formik.errors.advice_for && (
                  <span className="input-error-message">{formik.errors.advice_for}</span>
                )}
              </Form.Item>
              <Form.Item label="Description">
                <QuillEditor
                  theme="snow"
                  name="description"
                  value={formik.values.description}
                  onChange={(value) => formik.setFieldValue('description', value)}
                />
              </Form.Item>
              <Form.Item label="Intro">
                <QuillEditor
                  theme="snow"
                  name="intro"
                  value={formik.values.intro}
                  onChange={(value) => formik.setFieldValue('intro', value)}
                />
              </Form.Item>
              <Form.Item label="Body text">
                <QuillEditor
                  theme="snow"
                  name="body_text"
                  value={formik.values.body_text}
                  onChange={(value) => formik.setFieldValue('body_text', value)}
                />
              </Form.Item>
              <Form.Item label="Summary">
                <QuillEditor
                  theme="snow"
                  name="summary"
                  value={formik.values.summary}
                  onChange={(value) => formik.setFieldValue('summary', value)}
                />
              </Form.Item>
              <Form.Item label="Pros">
                <QuillEditor
                  theme="snow"
                  name="pros"
                  value={formik.values.pros}
                  onChange={(value) => formik.setFieldValue('pros', value)}
                />
              </Form.Item>
              <Row gutter={16} className="compact_rows header-row">
                <Col span={20}>
                  <strong>Benefit</strong>
                </Col>
                <Col span={3}>
                  <strong>Is required</strong>
                </Col>
              </Row>
              {benefits.map((b, index) => (
                <Row key={index} gutter={16} className={'compact_rows'}>
                  <Col span={20}>
                    <Form.Item>
                      <Select
                        mode="single"
                        name="benefit"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select benefit"
                        value={b.benefit}
                        onChange={(value) => handleBenefitChange(index, 'benefit', value)}
                      >
                        {benefit_risk_list.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.subject}
                          </Option>
                        ))}
                      </Select>
                      {formik.touched.benefits && get(formik.errors, 'benefits', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.benefits[index].benefit}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item>
                      <Select
                        mode="single"
                        name="required"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select required"
                        value={b.required}
                        onChange={(value) => handleBenefitChange(index, 'required', value)}
                      >
                        <Option value=""></Option>
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                      </Select>
                      {formik.touched.benefits && get(formik.errors, 'benefits', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.benefits[index].required}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={1} className={'delete_btn_column'}>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size="medium"
                      onClick={() => handleRemoveBenefit(index)}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                type="dashed"
                onClick={handleAddBenefit}
                style={{ width: '100%', marginBottom: '20px' }}
              >
                Add benefit
              </Button>
              <Form.Item label="Cons">
                <QuillEditor
                  theme="snow"
                  name="cons"
                  value={formik.values.cons}
                  onChange={(value) => formik.setFieldValue('cons', value)}
                />
              </Form.Item>

              <Row gutter={16} className="compact_rows header-row">
                <Col span={20}>
                  <strong>Risk</strong>
                </Col>
                <Col span={3}>
                  <strong>Is required</strong>
                </Col>
              </Row>
              {risks.map((b, index) => (
                <Row key={index} gutter={16} className={'compact_rows'}>
                  <Col span={20}>
                    <Form.Item>
                      <Select
                        mode="single"
                        name="risk"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select risk"
                        value={b.risk}
                        onChange={(value) => handleRiskChange(index, 'risk', value)}
                      >
                        {benefit_risk_list.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.subject}
                          </Option>
                        ))}
                      </Select>
                      {formik.touched.risks && get(formik.errors, 'risks', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.risks[index].risk}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item>
                      <Select
                        mode="single"
                        name="required"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select required"
                        value={b.required}
                        onChange={(value) => handleRiskChange(index, 'required', value)}
                      >
                        <Option value=""></Option>
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                      </Select>
                      {formik.touched.risks && get(formik.errors, 'risks', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.risks[index].required}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={1} className={'delete_btn_column'}>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size="medium"
                      onClick={() => handleRemoveRisk(index)}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                type="dashed"
                onClick={handleAddRisk}
                style={{ width: '100%', marginBottom: '20px' }}
              >
                Add risk
              </Button>

              {webLinks.map((link, index) => (
                <Row key={index} gutter={16} className={'compact_rows'}>
                  <Col span={11}>
                    <Form.Item label={`Web Name ${index + 1}`}>
                      <Input
                        placeholder="Web Name"
                        name="web_name"
                        value={link.web_name}
                        onChange={(event) => handleWebLinkChange(index, event)}
                      />
                      {formik.touched.web_links && get(formik.errors, 'web_links', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.web_links[index].web_name}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label={`Web Link ${index + 1}`}>
                      <Input
                        placeholder="Web Link"
                        name="web_link"
                        value={link.web_link}
                        onChange={(event) => handleWebLinkChange(index, event)}
                      />
                      {formik.touched.web_links && get(formik.errors, 'web_links', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.web_links[index].web_link}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={2} className={'delete_btn_column'}>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size="medium"
                      onClick={() => handleRemoveWebLink(index)}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                type="dashed"
                onClick={handleAddWebLink}
                style={{ width: '100%', marginBottom: '20px' }}
              >
                Add Web Link
              </Button>

              {videoLinks.map((link, index) => (
                <Row key={index} gutter={16} className={'compact_rows'}>
                  <Col span={11}>
                    <Form.Item label={`Video Icon ${index + 1}`}>
                      <Input
                        placeholder="Video Icon"
                        name="video_icon"
                        value={link.video_icon}
                        onChange={(event) => handleVideoLinkChange(index, event)}
                      />
                      {formik.touched.video_links &&
                        get(formik.errors, 'video_links', [])[index] && (
                          <span className="input-error-message">
                            {formik.errors.video_links[index].video_icon}
                          </span>
                        )}
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label={`Video Link ${index + 1}`}>
                      <Input
                        placeholder="Video Link"
                        name="video_link"
                        value={link.video_link}
                        onChange={(event) => handleVideoLinkChange(index, event)}
                      />
                      {formik.touched.video_links &&
                        get(formik.errors, 'video_links', [])[index] && (
                          <span className="input-error-message">
                            {formik.errors.video_links[index].video_link}
                          </span>
                        )}
                    </Form.Item>
                  </Col>
                  <Col span={2} className={'delete_btn_column'}>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size="medium"
                      onClick={() => handleRemoveVideoLink(index)}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                type="dashed"
                onClick={handleAddVideoLink}
                style={{ width: '100%', marginBottom: '20px' }}
              >
                Add Video Link
              </Button>
            </Form>
          </TabPane>
          <TabPane tab="Display info" key="2">
            {/*<CheckboxGroup*/}
            {/*  options={[*/}
            {/*    'Dependants',*/}
            {/*    'Estate Planning',*/}
            {/*    'Asset movement current',*/}
            {/*    'Asset movement proposed',*/}
            {/*    'Liability movement current',*/}
            {/*    'Liability movement proposed',*/}
            {/*  ]}*/}
            {/*/>*/}
            <Form.Item>
              <Checkbox
                // value={option.id}
                // style={{ marginRight: '8px' }}
                // disabled={option.required === 'yes'}
                >Dependants
            </Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox
                // value={option.id}
                // style={{ marginRight: '8px' }}
                // disabled={option.required === 'yes'}
                >Estate Planning
            </Checkbox>
            </Form.Item>
            <Form.Item>
                <Checkbox
                    name='show_analysis'
                    checked={formik.values.show_analysis}
                    onChange={(e) => formik.setFieldValue('show_analysis', e.target.checked)}
                >
                    Insurance needs analysis
                </Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox
                name='asset_movement_current'
                checked={formik.values.asset_movement_current}
                onChange={(e) => formik.setFieldValue('asset_movement_current', e.target.checked)}
                >Asset movement current
            </Checkbox>
            </Form.Item>
            <Form.Item label="Asset subtype">
              <Select
                showSearch
                  mode="single"
                  name="asset_subtype"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="select asset subtype"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().includes(input.toLowerCase())
                  }
                  value={formik.values.asset_subtype.value}
                  onChange={(value,option) => formik.setFieldValue("asset_subtype", {"label": option.children, "value": value})}
              >
                {get(asset_type_option, 'options',[]).map((item) => (
                    <Option key={item.id} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Checkbox
                name='asset_movement_proposed'
                checked={formik.values.asset_movement_proposed}
                onChange={(e) => formik.setFieldValue('asset_movement_proposed',  e.target.checked)}
                >Asset movement proposed
            </Checkbox>
            </Form.Item>
            <Form.Item label="Product type">
              <Select
                  mode="single"
                  name="asset_product_type"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select product type"
                  value={formik.values.asset_product_type}
                  onChange={(value) => formik.setFieldValue('asset_product_type', value)}
                >
                  <Option value=""></Option>
                  {product_type_list.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.subject}
                    </Option>
                  ))}
                </Select>
            </Form.Item>
            <Form.Item>
              <Checkbox
                name='asset_movement_current'
                checked={formik.values.liability_movement_current}
                onChange={(e) => formik.setFieldValue('liability_movement_current', e.target.checked)}
                >Liability movement current
            </Checkbox>
            </Form.Item>
            <Form.Item label="Liability subtype">
              <Select
                  showSearch
                  mode="single"
                  name="liability_subtype"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="select liability subtype"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().includes(input.toLowerCase())
                  }
                  value={formik.values.liability_subtype.value}
                  onChange={(value,option) => formik.setFieldValue("liability_subtype", {"label": option.children, "value": value})}
              >
                {get(liability_type_option, 'options',[]).map((item) => (
                    <Option key={item.id} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Checkbox
                name='liability_movement_proposed'
                checked={formik.values.liability_movement_proposed}
                onChange={(e) => formik.setFieldValue('liability_movement_proposed',  e.target.checked)}
                >Liability movement proposed
            </Checkbox>
            </Form.Item>
            <Form.Item label="Product type">
              <Select
                  mode="single"
                  name="asset_product_type"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select product type"
                  value={formik.values.asset_product_type}
                  onChange={(value) => formik.setFieldValue('asset_product_type', value)}
                >
                  <Option value=""></Option>
                  {product_type_list.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.subject}
                    </Option>
                  ))}
                </Select>
            </Form.Item>
            <Form.Item>
              <Checkbox
                name='life_insurance'
                checked={formik.values.life_insurance}
                onChange={(e) => formik.setFieldValue('life_insurance',  e.target.checked)}
                >Life insurance
            </Checkbox>
            </Form.Item>
            <Form.Item>
                <Checkbox
                    name='general_insurance'
                    checked={formik.values.general_insurance}
                    onChange={(e) => formik.setFieldValue('general_insurance', e.target.checked)}
                >
                    General insurance
                </Checkbox>
            </Form.Item>
            <Form.Item>
                <Checkbox
                    name='health_insurance'
                    checked={formik.values.health_insurance}
                    onChange={(e) => formik.setFieldValue('health_insurance', e.target.checked)}
                >
                    Private Health insurance
                </Checkbox>
            </Form.Item>
          </TabPane>
          <TabPane tab="Next steps" key="3">
            <Row gutter={16} className="compact_rows header-row">
              <Col span={14}>
                <strong>Implementation Step</strong>
              </Col>
              <Col span={3}>
                <strong>Action by</strong>
              </Col>
              <Col span={3}>
                <strong>Timing</strong>
              </Col>
              <Col span={3}>
                <strong>Is Required</strong>
              </Col>
            </Row>
            {advice_implementation_steps.map((b, index) => (
              <Row key={index} gutter={16} className={'compact_rows'}>
                <Col span={14}>
                  <Form.Item>
                    <Select
                      mode="single"
                      name="implementation_step"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Please select step"
                      value={b.implementation_step}
                      onChange={(value) =>
                        handleAdviceImplementationStepsChange(index, 'implementation_step', value)
                      }
                    >
                      {implementation_steps_list.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.subject}
                        </Option>
                      ))}
                    </Select>
                    {formik.touched.advice_implementation_steps &&
                      get(formik.errors, 'advice_implementation_steps', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.advice_implementation_steps[index].implementation_step}
                        </span>
                      )}
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item>
                    <Select
                      mode="single"
                      name="action_by"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="select action by"
                      value={b.action_by}
                      onChange={(value) =>
                        handleAdviceImplementationStepsChange(index, 'action_by', value)
                      }
                    >
                      <Option value=""></Option>
                      {Object.entries(action_by_option).map(([key, value]) => (
                        <Option key={key} value={key}>
                          {value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item>
                    <Select
                      mode="single"
                      name="timing"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="select timing"
                      value={b.timing}
                      onChange={(value) =>
                        handleAdviceImplementationStepsChange(index, 'timing', value)
                      }
                    >
                      <Option value=""></Option>
                      {Object.entries(timing_option).map(([key, value]) => (
                        <Option key={key} value={key}>
                          {value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item>
                    <Select
                      mode="single"
                      name="required"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Please select required"
                      value={b.required}
                      onChange={(value) =>
                        handleAdviceImplementationStepsChange(index, 'required', value)
                      }
                    >
                      <Option value=""></Option>
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                    {formik.touched.advice_implementation_steps &&
                      get(formik.errors, 'advice_implementation_steps', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.advice_implementation_steps[index].required}
                        </span>
                      )}
                  </Form.Item>
                </Col>
                <Col span={1} className={'delete_btn_column'} t>
                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    size="medium"
                    onClick={() => handleRemoveImplementationSteps(index)}
                  />
                </Col>
              </Row>
            ))}
            <Button
              type="dashed"
              onClick={handleAddImplementationSteps}
              style={{ width: '100%', marginBottom: '20px' }}
            >
              Add implementation step
            </Button>
          </TabPane>
          <TabPane tab="Fees and charges" key="4">
            Fees and charges
          </TabPane>
          <TabPane tab="Permissions" key="5">
            <CustomTree
              preview={preview}
              onCheckPerMission={onCheckPerMission}
              business_list={business_list}
              permissions={permissions}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  )
}
