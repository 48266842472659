import React, {
  Component,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import Nestable from 'react-nestable'
import {
  Select,
  Tabs,
  InputNumber,
  Radio,
  Space,
  Table,
  Input,
  Modal,
  Cascader,
  Button,
} from 'antd'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import update from 'immutability-helper'

import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  CloseSquareOutlined,
} from '@ant-design/icons'
import {
  AdminLayout,
  Boolean,
  Date as CustomDate, // rename Date to CustomDate to avoid naming collision
  MultiLineText,
  MultipleSelect,
  Number,
  SingleLineText,
  ElementField,
  CheckList,
  WorkbookSetting,
} from 'Components'
import './form.scss'
import Attachment from 'Components/Fields/attachment'
import RichText from 'Components/Fields/richText'
import SingleSelect from 'Components/Fields/singleSelect'
import Heading from 'Components/Fields/heading'
import Gap from 'Components/Fields/gap'
import ColorSelector from 'Components/Fields/color'
import ProviderField from 'Components/Fields/providerField'

// --- Services / Redux / Misc ---
import { get } from 'lodash'
import { AuthService, FormService, PageService, PageServiceService } from 'services'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import produce from 'immer'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ReactSortable } from 'react-sortablejs'
import { setUser, updateRoutes } from 'Store/reducers/previewUserSlice'
import { setRoutes } from 'Store/reducers/routeSlice'
import { getAdviseForOptions } from 'services/advise_for'
const { TabPane } = Tabs
const type = 'DraggableBodyRow'
const workbookGroupId = 'acdd415f-8543-4885-bb7c-485e7547a5a4'
const workbookGroupFieldId = '2e1fa45c-27b1-40b7-99e5-01f952eaa58e'
const progressGroupFieldId = 'ffeede29-6f12-47d1-b016-3915e9658432'
const progressGroupId = '25627427-7290-448e-b0d1-5db5d8ef8aad'
const progressFormFieldId = '08d641da-2b26-4861-9472-39603bb06279'

//----
const workbookFormId = 'c39bb863-e943-48ae-afaa-5279d1713dbb'

/**
 * DraggableBodyRow:
 *   Makes each Table row draggable (for the Form table).
 */
const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef(null)
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) return {}
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      }
    },
    drop: (item) => {
      moveRow(item.index, index)
    },
  })
  const [, drag] = useDrag({
    type,
    item: {
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  drop(drag(ref))
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{
        cursor: 'move',
        ...style,
      }}
      {...restProps}
    />
  )
}

/**
 * Main Component: PreviewInsurance
 */
const PreviewInsurance = forwardRef(
  (
    {
      visible,
      handleOk,
      handleCancel,
      items,
      name,
      noModal,
      displayName,
      showSave,
      preview,
      pageId,
      wizard_history,
      wizard_is_locked,
      hasSidebarCondition,
    },
    ref
  ) => {
    const dispatch = useDispatch()

    // ----- Redux State -----
    const previewUser = useSelector((state) => state.previewUser)
    const user = useSelector((state) => state.user)
    const option_data = useSelector((state) => state.entityReducer)

    // ----- React State -----
    const [data, setData] = useState({})
    const [groupData, setGroupData] = useState({})
    const [groupFields, setGroupFields] = useState({})
    const [attachments, setAttachments] = useState([])
    const [workbookStatuModal, setWorkbookStatusModal] = useState(false)
    const [workbookProgressModal, setWorkbookProgressModal] = useState(false)
    const [deleteAttachments, setDeleteAttachments] = useState([])
    const [formRecord, setFormRecord] = useState('')
    const [form, setForm] = useState(null)
    const [formVisible, setFormVisible] = useState(false)
    const [formBody, setFormBody] = useState({
      field_data: {},
      group_data: {},
    })
    const [formBodyReadOnly, setFormBodyReadOnly] = useState({
      field_data: {},
      group_data: {},
    })
    const [customFormData, setCustomFormData] = useState({})
    const [dataLoading, setDataLoading] = useState(false)
    const [formFields, setFormFields] = useState({})
    const [progressRecordId, setProgressRecordId] = useState('')
    const [formOptions, setFormOptions] = useState({})
    const [groupOptions, setGroupOptions] = useState({})
    const [workbookStatus, setWorkbookStatus] = useState('')
    const [statusRecordId, setStatusRecordId] = useState('')
    const [workbookProgress, setWorkbookProgress] = useState('')
    const [workbookProgressStatus, setWorkbookProgressStatus] = useState([])
    const [progressGroup, setProgressGroup] = useState('')
    const [formRecords, setFormRecords] = useState([])
    const [clients, setClients] = useState([])
    const [workbookLoading, setWorkbookLoading] = useState(false)
    const [showSaveButton, setShowSaveButton] = useState(false)
    const [conditions, setConditions] = useState({})
    const [pageConditions, setPageConditions] = useState({})
    const [formConditons, setFormConditons] = useState({})
    const [adviceForOptions, setAdviceForOptions] = useState({})

    // ----- Refs -----
    const timer = useRef(null)
    let debounceTimer = useRef(null)

    // ----- Extract Query Params / Entities -----
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)
    const entity = params.get('entity')
      ? params.get('entity')
      : preview && previewUser?.details
        ? previewUser?.details?.id
        : user?.details?.id

    // --------------------------------------
    //  Fetchers & Initialization
    // --------------------------------------
    useEffect(() => {
      getAdviseForOptions(entity).then((res) => {
        const optionsObj = res?.data?.data || {}
        setAdviceForOptions(optionsObj)
      })
    }, [entity])

    useEffect(() => {
      getClients()
      if (preview && previewUser.details) {
        getPageData()
        getGroupData(progressGroupId)
      } else if (!preview && user.details) {
        getPageData()
      }
    }, [previewUser, pageId, entity])

    useEffect(() => {
      if (items) {
        setShowSaveButton(false)
        prepareGroupData(items)
        prepareFormData(items)
        handleShowSave(items)
        if (pageId) prepareFormGroupOptions(items)
      }
    }, [items])

    const getClients = () => {
      AuthService.users({ user_type: 'client' }).then((res) => {
        setClients(get(res, 'data.data', []))
      })
    }

    // --------------------------------------
    //  Condition Logic
    // --------------------------------------
    const debounce = (cb) => {
      clearTimeout(debounceTimer.current)
      debounceTimer.current = setTimeout(() => {
        cb()
      }, 1200)
    }

    const fetchPageCondition = (page, data = {}) => {
      let body = {
        page,
        data,
      }
      PageService.getPageCondition(body, entity).then((res) => {
        setPageConditions(res.data.data)
      })
    }

    const fetchSingleGroupCondition = (group, data) => {
      let body = {
        group,
        data,
      }
      PageService.getGroupConditions(body).then((res) => {
        setConditions((prev) => ({ ...prev, [group]: res.data.data }))
      })
    }

    const fetchSingleFormCondition = (form, data) => {
      let body = {
        form,
        data,
      }
      PageService.getFormConditions(body).then((res) => {
        setFormConditons((prev) => ({ ...prev, [form]: res.data.data }))
      })
    }

    // --------------------------------------
    //  Data / Entities
    // --------------------------------------
    const getPageData = () => {
      if (!pageId) return
      PageService.getPageData({
        page: pageId,
        entity: entity,
        wizard_history: wizard_is_locked && wizard_history ? wizard_history : '',
        wizard: wizard_history ? wizard_history : '',
      })
        .then((res) => {
          setData(get(res, 'data.data.data', {}))
          fetchPageCondition(pageId, get(res, 'data.data.data', {}))
        })
        .finally(() => {
          setDataLoading(false)
        })
    }

    const getGroupData = (group, cb) => {
      setWorkbookLoading(true)
      let usedEntity = preview ? previewUser?.details?.id : user?.details?.id
      let entityDetails = preview ? previewUser?.details : user?.details

      // If group is the progress group but the user is a client, override entity with business
      if (group === progressGroupId && entityDetails?.user_type === 'client') {
        usedEntity = preview ? previewUser?.details?.business : user?.details?.business
      }
      PageService.getGroupData({
        group,
        entity: usedEntity,
        page: pageId,
        wizard_history: wizard_is_locked && wizard_history ? wizard_history : '',
      })
        .then((res) => {
          if (res.data.data && res.data.data.length) {
            const nextState = produce(groupData, (draft) => {
              draft[group] = res.data.data.map((item) => ({
                data: item.data,
                record_id: item.id,
                is_selected: item.is_selected,
              }))
            })
            setGroupData((prev) => ({ ...prev, ...nextState }))
          } else {
            // If no data, set up empty rows
            prepareGroupData(items, true)
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          if (cb) cb()
          setWorkbookLoading(false)
        })
    }
    const getFormRecords = (formId) => {
      PageService.getFormData({
        form: formId,
        entity,
        page: pageId,
        wizard_history: wizard_is_locked && wizard_history ? wizard_history : '',
      })
        .then((res) => {
          if (res.data.data && res.data.data.length) {
            const nextState = produce(customFormData, (draft) => {
              draft[formId] = res.data.data.map((item) => {
                let o = {
                  data: { ...item.field_data },
                  proposedData: { ...(item?.proposed_field_data?.field_data || {}) },
                  record_id: item.id,
                  body: {
                    field_data: item?.proposed_field_data?.field_data || {},
                    group_data: item?.proposed_field_data?.group_data || {},
                  },
                  readOnlyBody: {
                    field_data: item?.field_data || {},
                    group_data: item?.group_data || {},
                  },
                }
                // Merge group data into "data" for easy usage
                Object.keys(item.group_data).forEach((key) => {
                  o = {
                    ...o,
                    data: { ...o.data, ...item.group_data[key] },
                    proposedData: {
                      ...o.proposedData,
                      ...(item?.proposed_field_data?.group_data?.[key] || {}),
                    },
                  }
                })
                return o
              })
            })
            setCustomFormData((prev) => ({ ...prev, ...nextState }))
          } else {
            // If no data, prepare new empty form data
            prepareFormData(items, true)
          }
        })
        .catch((err) => console.log(err))
    }

    // --------------------------------------
    //  Prepare Data
    // --------------------------------------
    const flattenFields = (items, arr = []) => {
      for (let i = 0; i < items.length; i++) {
        let item = items[i]
        if (item.type === 'tab_group') {
          flattenFields(item.tabs, arr)
        } else if (item.children) {
          flattenFields(item.children, arr)
        } else {
          arr.push(item)
        }
      }
      return arr
    }

    const getFields = (items, fields = []) => {
      for (let i = 0; i < items.length; i++) {
        let el = items[i]
        if (el.type === 'field') {
          fields.push(el)
        }
        if (el.field) {
          fields.push(el)
        }
        if (el.type === 'form') {
          getFields(el.form.items, fields)
        }
        if (el.type === 'group') {
          getFields(el.group.fields, fields)
        } else if (el.type === 'tab_group') {
          getFields(el.tabs, fields)
        } else if (el.children) {
          getFields(el.children, fields)
        }
      }
      return fields
    }

    const getGroups = (items, groups = []) => {
      for (let i = 0; i < items.length; i++) {
        let el = items[i]
        if (el.type === 'group') {
          groups.push(el)
        } else if (el.type === 'tab_group') {
          getGroups(el.tabs, groups)
        } else if (el.children) {
          getGroups(el.children, groups)
        }
      }
      return groups
    }

    const prepareGroupData = (_items, getGroup) => {
      let obj = {}
      let _groupFields = {}
      let flattened = flattenFields(_items)

      flattened.forEach((item) => {
        if (item.type === 'group') {
          // If we have a pageId and we haven't already fetched this group's data, do so
          if (pageId && !getGroup) {
            getGroupData(item.group.id)
          }
          // Make an empty template for new records
          let fieldMap = { data: {} }
          item.group.fields.forEach((fld) => {
            fieldMap.data[fld.field.id] = ''
            _groupFields[fld.field.id] = fld
          })
          obj[item.group.id] = [fieldMap]
        }
      })
      setGroupData(obj)
      setGroupFields(_groupFields)
      return obj
    }

    const prepareFormData = (_items, getForm) => {
      let obj = {}
      let _formFields = {}
      let flattened = flattenFields(_items)

      flattened.forEach((item) => {
        if (item.type === 'form') {
          if (pageId && !getForm) {
            getFormRecords(item.form.id)
          }
          let fieldMap = { data: {} }
          let fields = getFields(item.form.items)
          fields.forEach((fld) => {
            if (fld.type === 'field' || fld.field) {
              fieldMap.data[fld.field.id] = ''
              _formFields[fld.field.id] = fld
            }
            if (fld.type === 'group') {
              fld.group.fields.forEach((subFld) => {
                fieldMap.data[subFld.field.id] = ''
                _formFields[subFld.field.id] = subFld
              })
            }
          })
          obj[item.form.id] = [fieldMap]
        }
      })
      setFormFields(_formFields)
    }

    const prepareFormGroupOptions = (items) => {
      let flattened = flattenFields(items)
      let fields = getFields(flattened)
      let selectFields = fields.filter(
        (item) => item?.field?.type === 'single_select' || item?.field?.type === 'multiple_select'
      )
      let trackObj = {}
      selectFields.forEach((item) => {
        if (!trackObj[item.field.id]) {
          trackObj[item.field.id] = true
          if (item?.field?.options_type === 'form') {
            PageService.getFormDropdown({ entity, field: item.field.id }).then((res) => {
              setFormOptions((prev) => ({ ...prev, [item.field.id]: res?.data?.data || [] }))
            })
          }
          if (item?.field?.options_type === 'group') {
            PageService.getGroupDropdown({ entity, field: item.field.id }).then((res) => {
              setGroupOptions((prev) => ({ ...prev, [item.field.id]: res?.data?.data || [] }))
            })
          }
        }
      })
    }

    // --------------------------------------
    //  Helpers to Get / Set Field Values
    // --------------------------------------
    /**
     * getDataValue:
     *   fetches the current stored value for a given field
     *   from page data, group data or form data.
     */
    const getDataValue = (formData, groupId, groupIndex, _form, takeFromReadOnly = false) => {
      const formBodyData = takeFromReadOnly ? formBodyReadOnly : formBody
      if (form && groupId) {
        // If we are in a custom form with a group
        return formBodyData.group_data?.[groupId]?.[formData.id]
      } else if (groupId) {
        // If we are in a group outside of a custom form
        return groupData[groupId]?.[groupIndex]?.data?.[formData.id]
      } else if (_form) {
        // If we are in a custom form (not a group)
        return formBodyData.field_data?.[formData.id]
      } else {
        // Standard page field
        return data[formData.id]
      }
    }

    /**
     * handleDataChange:
     *   updates local state and optionally triggers condition fetches
     */
    const handleDataChange = (val, formData, groupId, groupIndex, _form) => {
      // --- Group inside a custom form ---
      if (groupId && form) {
        let _formBody = JSON.parse(JSON.stringify(formBody))
        let _group_data = _formBody.group_data || {}
        if (!_group_data[groupId]) {
          _group_data[groupId] = {}
        }
        _group_data[groupId][formData.id] = val
        _formBody.group_data = _group_data

        if (val && formData.has_dependent_condition) {
          debounce(() => fetchSingleGroupCondition(groupId, _formBody?.group_data?.[groupId] || {}))
        }
        setFormBody(_formBody)
        return
      }

      // --- Group outside custom form ---
      if (groupId) {
        const nextState = produce(groupData, (draft) => {
          draft[groupId][groupIndex].data[formData.id] = val
        })
        setGroupData(nextState)
        return
      }

      // --- Inside a custom form (not a group) ---
      if (_form) {
        let _formBody = JSON.parse(JSON.stringify(formBody))
        let _field_data = _formBody.field_data || {}
        _field_data[formData.id] = val
        _formBody.field_data = _field_data

        if (val && formData.has_dependent_condition) {
          debounce(() => fetchSingleFormCondition(_form.form.id, _formBody.field_data || {}))
        }
        setFormBody(_formBody)
        return
      }

      // --- Regular page-level field ---
      if (formData.has_dependent_condition) {
        let pageData = JSON.parse(JSON.stringify(data))
        pageData[formData.id] = val
        debounce(() => fetchPageCondition(pageId, pageData || {}))
      }
      setData((prev) => {
        let _data = { ...prev }
        _data[formData.id] = val
        return _data
      })
    }

    /**
     * getDependantValue:
     *   used for fields that have dependsOn logic
     */
    const getDependantValue = (formData, groupId, groupIndex, _form) => {
      if (!formData.dependsOn) return undefined
      let depValue = getDataValue(formData.dependsOn, groupId, groupIndex, _form)
      let matched = formData.dependsOn.options.filter((opt) => opt.value === depValue)[0]
      return matched?.id
    }

    // --------------------------------------
    //  The Key Part: Render Each Field as a Table Row
    // --------------------------------------
    /**
     * createInputElement:
     *   returns the correct input element for the 3rd column
     */
    const createInputElement = (formData, currentValue, groupId, groupIndex, formObj) => {
      formData.exclude_label = true
      switch (formData.type) {
        case 'checklist':
          return (
            <CheckList
              {...formData}
              user={previewUser || user}
              clients={clients}
              value={(currentValue || []).map((item) => {
                let obj = { ...item }
                delete obj.chosen
                return obj
              })}
              onChange={(val) => handleDataChange(val, formData, groupId, groupIndex, formObj)}
            />
          )
        case 'color':
          return (
            <ColorSelector
              {...formData}
              value={currentValue}
              onChange={(val) => handleDataChange(val, formData, groupId, groupIndex, formObj)}
            />
          )
        case 'text':
          return (
            <SingleLineText
              {...formData}
              value={currentValue}
              onChange={(val) => handleDataChange(val, formData, groupId, groupIndex, formObj)}
              dependantValue={getDependantValue(formData, groupId, groupIndex, formObj)}
            />
          )
        case 'gap':
          return <Gap {...formData} />
        case 'textarea':
          return (
            <MultiLineText
              {...formData}
              value={currentValue}
              dependantValue={getDependantValue(formData, groupId, groupIndex, formObj)}
              onChange={(val) => handleDataChange(val, formData, groupId, groupIndex, formObj)}
            />
          )
        case 'attachment':
          return (
            <Attachment
              {...formData}
              value={currentValue}
              onChange={(val) => handleDataChange(val, formData, groupId, groupIndex, formObj)}
              setAttachments={(id) => setAttachments((prev) => [...prev, id])}
              setDeleteAttachments={(url) => setDeleteAttachments((prev) => [...prev, url])}
            />
          )
        case 'rich_text':
          return (
            <RichText
              {...formData}
              value={currentValue}
              dependantValue={getDependantValue(formData, groupId, groupIndex, formObj)}
              onChange={(val) => handleDataChange(val, formData, groupId, groupIndex, formObj)}
            />
          )
        case 'multiple_select':
          return (
            <MultipleSelect
              {...formData}
              value={currentValue}
              dependantValue={getDependantValue(formData, groupId, groupIndex, formObj)}
              onChange={(val) => handleDataChange(val, formData, groupId, groupIndex, formObj)}
            />
          )
        case 'single_select':
          return (() => {
            if (formData?.created_by) {
              // For a "created_by" field
              const fullName = previewUser?.details
                ? `${previewUser.details.first_name} ${previewUser.details.last_name}`
                : `${user.details.first_name} ${user.details.last_name}`
              return <SingleLineText {...formData} value={fullName} />
            }
            const PROVIDER_TYPES = [
              'product_provider',
              'asset_provider',
              'liability_provider',
              'general_insurance_provider',
              'life_insurance_provider',
              'health_insurance_provider',
            ]
            if (PROVIDER_TYPES.includes(formData.options_type)) {
              return (
                <ProviderField
                  {...formData}
                  value={currentValue}
                  dependantValue={getDependantValue(formData, groupId, groupIndex, formObj)}
                  onChange={(val) => handleDataChange(val, formData, groupId, groupIndex, formObj)}
                />
              )
            }
            return (
              <SingleSelect
                {...formData}
                value={currentValue}
                dependantValue={getDependantValue(formData, groupId, groupIndex, formObj)}
                onChange={(val) => handleDataChange(val, formData, groupId, groupIndex, formObj)}
              />
            )
          })()
        case 'heading':
          return <Heading {...formData} />
        case 'boolean':
          return (
            <Boolean
              {...formData}
              value={currentValue}
              dependantValue={getDependantValue(formData, groupId, groupIndex, formObj)}
              onChange={(val) => handleDataChange(val, formData, groupId, groupIndex, formObj)}
            />
          )
        case 'date':
          return (
            <CustomDate
              {...formData}
              value={currentValue}
              dependantValue={getDependantValue(formData, groupId, groupIndex, formObj)}
              onChange={(val) => handleDataChange(val, formData, groupId, groupIndex, formObj)}
            />
          )
        case 'number':
          return (
            <Number
              {...formData}
              value={currentValue}
              dependantValue={getDependantValue(formData, groupId, groupIndex, formObj)}
              onChange={(val) => handleDataChange(val, formData, groupId, groupIndex, formObj)}
            />
          )
        default:
          return null
      }
    }

    /**
     * _renderFieldRow:
     *   For each "field", we return a <tr> with 3 <td> (label, current value, input element).
     *   If conditions do not allow the field to appear, we return null.
     */
    const _renderFieldRow = (rawFieldData, groupId, groupIndex, formObj, itemId) => {
      if (!rawFieldData) return null

      // Check conditions for whether we should display
      if (groupId && !groupIndex && !conditions?.[groupId]?.[rawFieldData?.id]) {
        return null
      }

      if (formObj?.form?.id && !groupId && !formConditons?.[formObj.form.id]?.[itemId]) {
        return null
      }
      if (!groupId && !formObj?.form?.id && !pageConditions[itemId]) {
        return null
      }

      // Get the current value + label
      const currentReadOnlyValue = getDataValue(rawFieldData, groupId, groupIndex, formObj, true)
      const currentValue = getDataValue(rawFieldData, groupId, groupIndex, formObj)
      const labelText = rawFieldData.displayName || rawFieldData.name || 'Untitled Field'

      // Build the input element for the 3rd column
      const inputElement = createInputElement(
        rawFieldData,
        currentValue,
        groupId,
        groupIndex,
        formObj
      )

      // Return a table row
      return (
        <tr key={`field_${itemId || rawFieldData.id}`}>
          <td style={{ width: '30%', verticalAlign: 'top' }}>
            <strong>{labelText}</strong>
          </td>
          <td style={{ width: '35%', verticalAlign: 'top' }}>
            {/* {currentReadOnlyValue !== undefined && currentReadOnlyValue !== null
              ? JSON.stringify(currentReadOnlyValue)
              : ''} */}
            {getTableValue(rawFieldData.id, currentReadOnlyValue)}
          </td>
          <td style={{ width: '35%', verticalAlign: 'top' }}>{inputElement}</td>
        </tr>
      )
    }

    // --------------------------------------
    //  Render: Elements of the "Page" or "Form"
    // --------------------------------------
    /**
     * renderElementsOfForm:
     *   - We gather "field" items, produce <tr> for each
     *   - We handle groups, tabs, sub-forms, etc. in the same pass
     *   - The final result: a <table> that holds all direct field items.
     */
    const renderElementsOfForm = (itemsArr, formObj) => {
      if (!itemsArr || !itemsArr.length) return null

      // We'll accumulate rows for fields (and skip group/tab logic for them).
      let rowList = []

      itemsArr.forEach((item) => {
        // 1) If it's a direct field, create a row
        if (item.type === 'field' && item.field) {
          let newRow = _renderFieldRow(
            {
              ...item.field,
              has_dependent_condition: item?.has_dependent_condition,
            },
            null,
            null,
            formObj,
            item.id
          )
          if (newRow) rowList.push(newRow)
        }

        // 2) Groups: we skip rendering them as a table row. Instead, we handle them differently
        if (item.type === 'group') {
          // if we want to show group as a sub-table or something, we could do so here
          // but let's keep the existing function calls
          if (formObj) {
            // if we are inside a form, we do renderNestedGroup
            const nested = renderNestedGroup(item)
            // nested might be a list of <tr>, so we can push them
            if (Array.isArray(nested)) rowList.push(...nested)
          } else {
            // normal group outside a form
            rowList.push(
              <tr key={`group_${item.group.id}`}>
                <td colSpan={3}>{renderGroup(item)}</td>
              </tr>
            )
          }
        }

        // 3) Tab Groups: render each tab in a sub-section
        if (item.type === 'tab_group') {
          rowList.push(
            <tr key={`tab_group_${item.id}`}>
              <td colSpan={3}>{renderTabs(item, formObj)}</td>
            </tr>
          )
        }

        // 4) Forms: if we see a sub-form
        if (item.type === 'form') {
          // we can embed its content in a single row or multiple rows
          rowList.push(
            <tr key={`form_${item.form.id}`}>
              <td colSpan={3}>{renderForm(item.form.items, item)}</td>
            </tr>
          )
        }

        // 5) Elements (like workbook or progress elements)
        if (item.type === 'element') {
          rowList.push(
            <tr key={`element_${item.id}`}>
              <td colSpan={3}>{renderElement(item)}</td>
            </tr>
          )
        }
      })

      // If we have no rows, return null
      if (!rowList.length) return null

      // Return a table containing the rows
      return (
        <table className="fields_table" style={{ width: '100%', borderCollapse: 'collapse' }}>
          {formObj ? (
            <thead>
              <tr style={{ background: '#f7f7f7' }}>
                <th style={{ width: '30%', border: '1px solid #ddd', padding: '8px' }}>Field</th>
                <th style={{ width: '35%', border: '1px solid #ddd', padding: '8px' }}>
                  Current Value
                </th>
                <th style={{ width: '35%', border: '1px solid #ddd', padding: '8px' }}>
                  Proposed Value
                </th>
              </tr>
            </thead>
          ) : null}

          <tbody>{rowList}</tbody>
        </table>
      )
    }

    /**
     * renderNestedGroup:
     *   This was originally returning multiple fields.
     *   Now we adapt it to return an array of <tr>.
     */
    const renderNestedGroup = (group) => {
      if (!group || !group.group || !group.group.fields) return []
      // Return an array of <tr> for each field
      return group.group.fields.map((fld, idx) => {
        return _renderFieldRow(
          {
            ...fld.field,
            has_dependent_condition: fld?.has_dependent_condition,
          },
          group.group.id,
          0, // groupIndex = 0 or none
          form,
          fld.field.id
        )
      })
    }

    /**
     * renderGroup:
     *   Renders a "group" that may have multiple records
     */
    const renderGroup = (item) => {
      if (!groupData || !Object.keys(groupData).length) return null
      let gId = item.group.id
      let groupRecords = groupData[gId] || []

      return (
        <div>
          {item.display_heading && <h2>{item?.group?.display_name || item?.group?.name}</h2>}
          <ReactSortable
            list={groupRecords.map((rec) => ({ ...rec, chosen: true }))}
            onEnd={(e, val) => {
              if (!groupRecords.filter((r) => r.record_id).length) return
              clearTimeout(timer.current)
              timer.current = setTimeout(() => {
                PageService.groupReorder(
                  groupRecords.filter((r) => r.record_id).map((r) => r.record_id)
                )
              }, 1500)
            }}
            setList={(val) => {
              setGroupData((prev) => ({ ...prev, [gId]: val }))
            }}
          >
            {groupRecords.map((itm, idx) => (
              <div key={`group_record_${itm.record_id || idx}`} className="inline_group_fields">
                {/* For each record in the group, we display each field as a table row or inline */}
                <table
                  className="fields_table"
                  style={{ width: '100%', borderCollapse: 'collapse', marginBottom: 20 }}
                >
                  <thead>
                    <tr style={{ background: '#f7f7f7' }}>
                      <th style={{ width: '30%', border: '1px solid #ddd', padding: '8px' }}>
                        Field
                      </th>
                      <th style={{ width: '35%', border: '1px solid #ddd', padding: '8px' }}>
                        Current Value
                      </th>
                      <th style={{ width: '35%', border: '1px solid #ddd', padding: '8px' }}>
                        Proposed Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(itm.data).map((fieldId, index) => {
                      let fObj = groupFields[fieldId]
                      if (!fObj) return null
                      return _renderFieldRow(
                        {
                          ...fObj.field,
                          isReadOnly: item.view_only,
                        },
                        gId,
                        idx,
                        null, // not a sub-form
                        fieldId
                      )
                    })}
                  </tbody>
                </table>
                {/* Action Buttons for each record */}
                {!item.view_only && (
                  <div className="each_group_field action_record_buttons">
                    <div className="form_field">
                      <label>Action</label>
                      <div className="align_button_container">
                        <Button
                          size="large"
                          type="link"
                          onClick={() => {
                            let body = {
                              group: gId,
                              data: groupRecords[idx].data,
                              page: pageId,
                              attachments,
                              delete_attachments: deleteAttachments,
                            }
                            if (preview && previewUser) {
                              body.entity = { id: previewUser.details.id }
                              if (previewUser.details.user_type === 'business') {
                                body.entity.type = 'business'
                              }
                            } else {
                              body.entity = { id: user.details.id }
                            }
                            if (itm.record_id) {
                              PageService.updateGroupData(body, itm.record_id).then(() =>
                                getGroupData(gId)
                              )
                            } else {
                              PageService.saveGroupData(body).then(() => getGroupData(gId))
                            }
                          }}
                          icon={<SaveOutlined />}
                        />
                        {itm.record_id ? (
                          <Button
                            size="large"
                            danger
                            icon={<CloseSquareOutlined />}
                            type="link"
                            onClick={() => {
                              PageService.deleteGroupRecord(itm.record_id).then(() => {
                                getGroupData(gId)
                              })
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </ReactSortable>
          {/* Add new record button */}
          {/* {!item.view_only && (
            <Button
              icon={<PlusOutlined />}
              type="primary"
              style={{ width: '100%' }}
              disabled={item.view_only}
              onClick={() => addGroupRecord(gId)}
            >
              Add new record
            </Button>
          )} */}
        </div>
      )
    }

    /**
     * renderTabs:
     *   Renders a tab group. Each tab has children -> we again call renderElementsOfForm.
     */
    const renderTabs = (item, formObj) => {
      let tabs = item.tabs || []
      let conds = {}
      if (formObj?.form?.id) {
        conds = formConditons[formObj.form.id] || {}
      } else {
        conds = pageConditions
      }
      return (
        <Tabs defaultActiveKey="0" type="card" size="small">
          {tabs
            .filter((tb) => conds?.[tb.id] !== false) // if condition is false, skip
            .map((tab, idx) => (
              <TabPane tab={tab.name} key={idx}>
                {tab.description && <p>{tab.description}</p>}
                {renderElementsOfForm(tab.children, formObj)}
              </TabPane>
            ))}
        </Tabs>
      )
    }

    /**
     * renderElement:
     *   Renders an "element" type item (like workbook or progress card).
     */
    const renderElement = (item) => {
      if (workbookLoading) return null
      return (
        <ElementField
          getFormRecords={getFormRecords}
          handleWorkbookCardClick={handleWorkbookCardClick}
          formRecords={customFormData[workbookFormId]}
          getFormDetails={getFormDetails}
          deleteRecord={(id, groupId) => {
            PageService.deleteGroupRecord(id).then(() => getGroupData(groupId))
          }}
          addWorkbookSettingStatus={(id, val) => {
            addGroupRecord(workbookGroupId, true)
            setWorkbookStatusModal(true)
            setWorkbookStatus(val || '')
            setStatusRecordId(id || '')
          }}
          addWorkbookSettingProgress={(id, val, progresses) => {
            getProgressGroup()
            addGroupRecord(progressGroupId, true)
            setWorkbookProgressModal(true)
            setWorkbookProgress(val || '')
            setProgressRecordId(id || '')
            setWorkbookProgressStatus(progresses?.map((p) => p.id) || [])
          }}
          progress={groupData[progressGroupId]}
          statuses={groupData[workbookGroupId]}
          element={item}
          handleDragChange={handleWorkbookSettingDrag}
          handleWorkbookDrag={handleWorkbookDrag}
          preview={preview}
          wizard_history={wizard_history}
          wizard_is_locked={wizard_history}
        />
      )
    }

    // --------------------------------------
    //  Workbook / Progress Logic
    // --------------------------------------
    const getProgressGroup = () => {
      PageServiceService.GroupDetail(progressGroupId).then((res) => {
        setProgressGroup(res?.data?.data)
      })
    }

    // Drag for workbook status or progress
    const handleWorkbookSettingDrag = (val) => {
      let newData = JSON.parse(JSON.stringify(val))
      newData.data[progressGroupFieldId] = newData.data[progressGroupFieldId].map((item) => item.id)
      _editProgressDrag(newData.data, val.record_id)
    }

    const handleWorkbookDrag = (val) => {
      let newData = JSON.parse(JSON.stringify(val))
      newData.data[progressFormFieldId] = newData.data[progressFormFieldId].map((item) => item.id)
      _editProgressDrag(newData.data, val.record_id)
    }

    const _editProgressDrag = (data, id) => {
      setWorkbookLoading(true)
      let body = {
        group: progressGroupId,
        data,
        page: pageId,
      }
      if (preview && previewUser) {
        body.entity = { id: previewUser.details.id }
        if (previewUser.details.user_type === 'business') {
          body.entity.type = 'business'
        }
      } else {
        body.entity = { id: user.details.id }
      }
      PageService.updateGroupData(body, id).then(() => {
        getGroupData(progressGroupId, () => setWorkbookLoading(false))
        getGroupData(workbookGroupId)
      })
    }

    // Save new status for the workbook
    const _saveGroupData = (groupId, groupIndex) => {
      let body = {
        group: workbookGroupId,
        data: { [workbookGroupFieldId]: workbookStatus },
        page: pageId,
      }
      if (preview && previewUser) {
        body.entity = { id: previewUser.details.id }
        if (previewUser.details.user_type === 'business') {
          body.entity.type = 'business'
        }
      } else {
        body.entity = { id: user.details.id }
      }
      if (statusRecordId) {
        PageService.updateGroupData(body, statusRecordId)
          .then(() => {
            setWorkbookStatusModal(false)
            getGroupData(workbookGroupId)
          })
          .finally(() => {
            setStatusRecordId('')
            setWorkbookStatus('')
          })
      } else {
        PageService.saveGroupData(body)
          .then(() => {
            setWorkbookStatusModal(false)
            getGroupData(workbookGroupId)
          })
          .finally(() => {
            setStatusRecordId('')
            setWorkbookStatus('')
          })
      }
    }

    // Save new progress for the workbook
    const _saveProgressGroupData = (groupId, groupIndex) => {
      let body = {
        group: progressGroupId,
        data: {
          [workbookGroupFieldId]: workbookProgress,
          [progressGroupFieldId]: workbookProgressStatus,
        },
        page: pageId,
      }
      if (preview && previewUser) {
        body.entity = { id: previewUser.details.id }
        if (previewUser.details.user_type === 'business') {
          body.entity.type = 'business'
        }
      } else {
        body.entity = { id: user.details.id }
      }
      setWorkbookLoading(true)
      if (progressRecordId) {
        PageService.updateGroupData(body, progressRecordId)
          .then(() => {
            setWorkbookProgressModal(false)
            getGroupData(progressGroupId, () => setWorkbookLoading(false))
          })
          .finally(() => {
            setProgressRecordId('')
            setWorkbookProgress('')
            setWorkbookProgressStatus([])
          })
      } else {
        PageService.saveGroupData(body)
          .then(() => {
            setWorkbookProgressModal(false)
            getGroupData(progressGroupId, () => setWorkbookLoading(false))
          })
          .finally(() => {
            setProgressRecordId('')
            setWorkbookProgress('')
            setWorkbookProgressStatus([])
          })
      }
    }

    // Create a new empty record in a group
    const addGroupRecord = (groupId, isWorkbook) => {
      let temp = { ...groupData }
      let arr = [...(temp[groupId] || [])]
      // create a new record from the last one or empty
      arr.push({ ...arr[arr.length - 1], record_id: null })
      temp[groupId] = arr
      setGroupData(temp)
    }

    // --------------------------------------
    //  Forms
    // --------------------------------------
    const renderForm = (itemsArr, formObj) => {
      // Original function used antd <Table> with draggable rows for form records
      // We'll keep it as-is, because it's a "summary" table of multiple records
      // within the form. Then, for each new record, we open a modal with
      // "renderElementsOfForm" to fill out fields in a table.

      // 1) Collect fields from groups (the "include" fields)
      let fields = []
      let groups = getGroups(itemsArr)
      groups.forEach((grp) =>
        grp.group.fields.forEach((f) => {
          if (f.include) fields.push(f)
        })
      )
      
      // 2) Build columns for the summary table
      let columns = fields.map((it) => ({
        title: it.field.displayName || it.field.name,
        key: it.id,
        dataIndex: it.field.id,
        width: it.percent ? `${it.percent}%` : `${100 / fields.length}%`,
        render: (val, recordData) => {
          return (
            <div className="table_responsive_cell">
              <h6>{it.field.displayName || it.field.name}</h6>
              {/* The "getTableValue" is your original function to display data in the summary */}
              {getTableValue(it.field.id, val)}
            </div>
          )
        },
      }))

      // 3) Build dataSource from customFormData
      const dataSource = customFormData[formObj.form.id]
        ?.filter((rec) => rec.record_id)
        .map((rec) => ({
          ...rec.proposedData,
          ...rec,
        }))

      // 4) Add action column for editing/deleting
      if (columns.length && !formObj.view_only) {
        columns.push({
          title: 'Action',
          key: 'action',
          width: '250px',
          render: (text, record) => (
            <Space size="middle">
              <Button
                onClick={() => {
                  fetchGroupCondition(formObj, record)
                  setFormVisible(true)
                  setForm(formObj)
                  setFormBody(record.body)
                  setFormBodyReadOnly(record.readOnlyBody)
                  setFormRecord(record.record_id)
                }}
                icon={<EditOutlined />}
                size="medium"
              />
              <Button
                danger
                icon={<DeleteOutlined />}
                size="medium"
                onClick={() => {
                  PageService.deleteFormData(record.record_id).then(() => {
                    getFormRecords(formObj.form.id)
                  })
                }}
              />
            </Space>
          ),
        })
      }

      // 5) Return the table plus a button to add new record
      return (
        <div>
          {formObj.display_heading && <h2>{formObj?.form?.display_name || formObj?.form?.name}</h2>}
          {columns.length ? (
            <DndProvider backend={HTML5Backend}>
              <Table
                className="custom_responsive_table"
                components={{ body: { row: DraggableBodyRow } }}
                onRow={(_, index) => {
                  const attr = {
                    index,
                    moveRow: (dragIndex, hoverIndex) => {
                      const dragRow = dataSource[dragIndex]
                      let sorted = update(dataSource, {
                        $splice: [
                          [dragIndex, 1],
                          [hoverIndex, 0, dragRow],
                        ],
                      })
                      let obj = { ...customFormData }
                      obj[formObj.form.id] = sorted.map((it) => ({
                        body: it.body,
                        record_id: it.record_id,
                        data: it.data,
                      }))
                      // setCustomFormData(obj) // optional if we want to update state
                      PageService.formReorder(sorted.map((it) => it.record_id))
                    },
                  }
                  return attr
                }}
                columns={columns}
                dataSource={dataSource}
              />
            </DndProvider>
          ) : null}
          {/* {!formObj.view_only ? (
            <Button
              icon={<PlusOutlined />}
              type="primary"
              style={{ width: '100%' }}
              size="large"
              onClick={() => {
                // Open modal for new record
                setFormVisible(true)
                setForm(formObj)
                fetchGroupCondition(formObj)
              }}
            >
              Add new record
            </Button>
          ) : null} */}
        </div>
      )
    }

    const fetchGroupCondition = (formObj, record) => {
      // Original logic to fetch group conditions for form
      fetchSingleFormCondition(formObj?.form?.id, record?.body?.field_data || {})
      let fields = flattenFields(formObj.form.items)
      fields.forEach((it) => {
        if (it.type === 'group') {
          let data = {}
          if (record) {
            data = record?.body?.group_data[it.group.id] || {}
          }
          fetchSingleGroupCondition(it.group.id, data)
        }
      })
    }

    // Original getTableValue from your code
    const booleanResolver = (val) => {
      switch (val) {
        case 'no':
          return 'No'
        case 'yes':
          return 'Yes'
        case 'true':
          return 'True'
        case 'false':
          return 'False'
        default:
          return ''
      }
    }
    const getTableValue = (fieldId, value) => {
      if (!value) return ''
      let fieldObj = formFields[fieldId]?.field
      if (!fieldObj) return JSON.stringify(value)

      let type = fieldObj?.type
      // ... your original checks ...
      if (type === 'checklist') {
        if (Array.isArray(value)) {
          return value.map((item, idx) => {
            let assignedName =
              clients?.filter((it) => it.id === item?.assigned_to)?.[0]?.name || '---'
            return (
              <p key={idx}>
                Description: {item?.description || '---'} | Assigned to: {assignedName}
              </p>
            )
          })
        }
      }
      if (type === 'boolean') {
        return booleanResolver(value)
      }
      if (type === 'single_select') {
        // ... same logic ...
        if (
          fieldObj.options_type === 'product_provider' ||
          fieldObj.options_type === 'asset_provider' ||
          fieldObj.options_type === 'liability_provider' ||
          fieldObj.options_type === 'life_insurance_provider'
        ) {
          return `${value?.providerName}- ${value?.productName}` || value?.provider
        }
        if (fieldObj.options_type === 'advise_for') {
          return adviceForOptions[value]
        }
        if (fieldObj.options_type === 'business') {
          return option_data.business.filter((it) => it.id === value)?.[0]?.business_name
        }
        if (fieldObj.options_type === 'user') {
          return option_data.user.filter((it) => it.id === value)?.[0]?.name
        }
        if (fieldObj.options_type === 'client') {
          return option_data.client.filter((it) => it.id === value)?.[0]?.name
        }
        let found = (fieldObj.options || []).filter((it) => it.value === value)[0]
        return found?.name || JSON.stringify(value)
      }
      if (type === 'text' || type === 'textarea') {
        if (fieldObj.dynamicFields && Array.isArray(value)) {
          return value.map((val, idx) => <div key={idx}>{val}</div>)
        }
        return value
      }
      if (type === 'number') {
        // ... same logic ...
        let prefix = fieldObj.numberFormat === 'currency' ? fieldObj.currency : ''
        if (fieldObj.dynamicFields && Array.isArray(value)) {
          // handle array of number objects
          return value.map((val, idx) => {
            if (fieldObj.numberType === 'single') return <div key={idx}>{prefix + val.single}</div>
            if (fieldObj.numberType === 'range')
              return <div key={idx}>{val.start + ' - ' + val.end}</div>
            if (fieldObj.numberType === 'flat_var')
              return <div key={idx}>{val.flat + ' - ' + val.variable}</div>
            if (fieldObj.numberType === 'flat_var_range')
              return (
                <div key={idx}>
                  {val.start + ' - ' + val.end + ' ' + val.flat + ' + ' + val.variable}
                </div>
              )
            return <div key={idx}>{JSON.stringify(val)}</div>
          })
        }
        // Single object
        if (fieldObj.numberType === 'single') return prefix + value.single
        if (fieldObj.numberType === 'range') return value.start + ' - ' + value.end
        if (fieldObj.numberType === 'flat_var') return value.flat + ' - ' + value.variable
        if (fieldObj.numberType === 'flat_var_range')
          return value.start + ' - ' + value.end + ' ' + value.flat + ' + ' + value.variable
        return JSON.stringify(value)
      }
      if (type === 'date') {
        if (fieldObj.dateVariant === 'dateRange' && Array.isArray(value)) {
          if (!value.length) return ''
          let start = moment(value[0]).format('DD-MM-YYYY')
          let end = moment(value[1]).format('DD-MM-YYYY')
          return start + ' - ' + end
        } else {
          return moment(value).format('DD-MM-YYYY')
        }
      }
      if (type === 'rich_text') {
        return <p dangerouslySetInnerHTML={{ __html: value }} />
      }
      if (type === 'multiple_select') {
        if (Array.isArray(value)) {
          let arr = value.map(
            (val) => (fieldObj.options || []).filter((opt) => opt.value === val)?.[0]?.name
          )
          return arr.join(', ')
        }
        if (typeof value === 'object' && Object.keys(value)?.length) {
          const optionsType = fieldObj.options_type
          return Object.keys(value).map((key) => {
            let theOpts =
              optionsType === 'form'
                ? formOptions?.[fieldId]
                : optionsType === 'group'
                  ? groupOptions?.[fieldId]
                  : fieldObj.options || []
            let found = theOpts.filter((op) => op.value === key)[0]
            let nameVal =
              optionsType === 'advise_for' && !found ? adviceForOptions[key] : found?.name || key
            return (
              <p key={key}>
                {nameVal} - {value[key]}
                {fieldObj?.display === 'percentage' ? '%' : ''}
              </p>
            )
          })
        }
      }
      if (type === 'attachment') {
        if (fieldObj.multiAttachments && Array.isArray(value)) {
          return value.map((url, idx) => (
            <a href={url} target="_blank" rel="noreferrer" key={idx}>
              {url.split('/')[url.split('/').length - 1]}
            </a>
          ))
        }
        return (
          <a href={value} target="_blank" rel="noreferrer">
            {value.split('/')[value.split('/').length - 1]}
          </a>
        )
      }
      // default
      return JSON.stringify(value)
    }

    // --------------------------------------
    //  Opening / Closing Modal for a Custom Form
    // --------------------------------------
    const handleWorkbookCardClick = (formRecord) => {
      // For workbook elements
      getFormDetails()
      setFormBody(formRecord.body)
      setFormRecord(formRecord.record_id)
    }

    const getFormDetails = () => {
      FormService.getForm(workbookFormId).then((res) => {
        let newForm = {
          display_heading: false,
          element: null,
          field: null,
          form: res.data.data,
          group: null,
          reorder: null,
          type: 'form',
          view_only: false,
        }
        setForm(newForm)
        setFormVisible(true)
      })
    }

    const closeFormModal = () => {
      setFormVisible(false)
      setForm(null)
      setFormBody({})
      setFormRecord('')
    }
    const handleFormOk = () => {
      closeFormModal()
    }
    const handleFormCancel = () => {
      closeFormModal()
    }

    // --------------------------------------
    //  Save Data
    // --------------------------------------
    const savePageData = () => {
      let obj = {
        data,
        page: pageId,
        attachments,
        delete_attachments: deleteAttachments,
        wizard_history: wizard_is_locked && wizard_history ? wizard_history : '',
        wizard: wizard_history ? wizard_history : '',
      }
      if (preview && previewUser) {
        obj.entity = { id: previewUser.details.id }
        if (previewUser.details.user_type === 'business') {
          obj.entity.type = 'business'
        }
      } else {
        obj.entity = { id: user.details.id }
      }
      return PageService.updatePageData(obj, {
        page: pageId,
        entity: preview ? previewUser.details.id : user.details.id,
      })
    }

    // forwardRef -> parent can call this method
    useImperativeHandle(ref, () => ({
      savePageData,
    }))

    const saveFormData = () => {
      if (!form) return
      let obj = {
        form: form.form.id,
        delete_attachments: deleteAttachments,
        attachments,
        page: pageId,
        ...formBody,
      }
      if (preview && previewUser) {
        obj.entity = { id: previewUser.details.id }
        if (previewUser.details.user_type === 'business') {
          obj.entity.type = 'business'
        }
      } else {
        obj.entity = { id: user.details.id }
      }
      obj.proposed_field_data = JSON.parse(JSON.stringify(obj))
      obj.wizard_history = wizard_history
      obj.field_data = formBodyReadOnly.field_data
      obj.group_data = formBodyReadOnly.group_data
      if (formRecord) {
        // Update existing record
        PageService.updateFormData(obj, formRecord).then(() => {
          closeFormModal()
          getFormRecords(form.form.id)
        })
      } else {
        // Create new record
        PageService.saveFormData(obj).then((res) => {
          closeFormModal()
          getFormRecords(form.form.id)
        })
      }
    }

    // Helper to see if we should show page-level "Save"
    const handleShowSave = (itemsArr) => {
      for (let i = 0; i < itemsArr.length; i++) {
        let el = itemsArr[i]
        if (el.type === 'field') {
          setShowSaveButton(true)
        }
        if (el.type === 'tab_group' && Array.isArray(el.tabs)) {
          el.tabs.forEach((tab) => handleShowSave(tab.children || []))
        }
      }
    }

    // --------------------------------------
    //  Rendering the Final Output
    // --------------------------------------
    if (noModal) {
      // Render as a page layout
      return (
        <div className="page_layout_dynamic">
          <h1 className="flex_title">
            <span className="title">{displayName || name}</span>
            {showSave && showSaveButton && !wizard_history ? (
              <Button
                size="large"
                type="primary"
                className="page_save_action"
                onClick={() => {
                  savePageData().then(() => {
                    if (hasSidebarCondition) {
                      AuthService.detailEntity(entity, { detail: true }).then((res) => {
                        dispatch(setRoutes(res.data.data.current_page_config))
                      })
                    }
                  })
                }}
              >
                Save
              </Button>
            ) : null}
          </h1>

          {/* Main Render */}
          {!dataLoading && renderElementsOfForm(items)}

          {/* Form Modal (for sub-records) */}
          {formVisible && (
            <Modal
              visible={formVisible}
              title="Add Record"
              onOk={handleFormOk}
              onCancel={handleFormCancel}
              footer={null}
              width={1800}
              style={{ maxWidth: '90%' }}
            >
              {form?.display_heading && <h2>{form?.form?.display_name || form?.form?.name}</h2>}
              {!dataLoading && renderElementsOfForm(form?.form?.items || [], form)}
              <Button onClick={saveFormData} type="primary" style={{ width: '100%' }} size="large">
                Save
              </Button>
            </Modal>
          )}

          {/* Workbook Status Modal */}
          {workbookStatuModal && (
            <Modal
              visible={workbookStatuModal}
              title="Add Status"
              footer={null}
              width={1800}
              style={{ maxWidth: '90%' }}
              onCancel={() => setWorkbookStatusModal(false)}
              onOk={() => setWorkbookStatusModal(false)}
            >
              <SingleLineText
                name="Name"
                layout="horizontal"
                value={workbookStatus}
                onChange={(val) => setWorkbookStatus(val)}
              />
              <Button
                type="primary"
                style={{ width: '100%' }}
                size="large"
                onClick={_saveGroupData}
              >
                Save
              </Button>
            </Modal>
          )}

          {/* Workbook Progress Modal */}
          {workbookProgressModal && (
            <Modal
              visible={workbookProgressModal}
              title="Add Progress"
              footer={null}
              width={1800}
              style={{ maxWidth: '90%' }}
              onCancel={() => setWorkbookProgressModal(false)}
              onOk={() => setWorkbookProgressModal(false)}
            >
              <SingleLineText
                name="Name"
                layout="horizontal"
                value={workbookProgress}
                onChange={(val) => setWorkbookProgress(val)}
              />
              {/* You had a MultipleSelect here, left commented out */}
              <Button
                type="primary"
                style={{ width: '100%' }}
                size="large"
                onClick={_saveProgressGroupData}
              >
                Save
              </Button>
            </Modal>
          )}
        </div>
      )
    }

    // Otherwise, render inside a Modal
    return (
      <Modal
        visible={visible}
        title={`${name} preview`}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1800}
        style={{ maxWidth: '90%' }}
      >
        <h2>{displayName || name}</h2>
        {!dataLoading && renderElementsOfForm(items)}

        {/* The sub-form modal (if user is editing or adding a custom form record) */}
        {formVisible && (
          <Modal
            visible={formVisible}
            title="Add Record"
            onOk={handleFormOk}
            onCancel={handleFormCancel}
            footer={null}
            width={1800}
            style={{ maxWidth: '90%' }}
          >
            {form?.display_heading && <h2>{form?.form?.display_name || form?.form?.name}</h2>}
            {!dataLoading && renderElementsOfForm(form?.form?.items || [], form)}
            <Button onClick={saveFormData} type="primary" style={{ width: '100%' }} size="large">
              Save
            </Button>
          </Modal>
        )}

        {/* Workbook Status Modal */}
        {workbookStatuModal && (
          <Modal
            visible={workbookStatuModal}
            title="Add Status"
            footer={null}
            width={1800}
            style={{ maxWidth: '90%' }}
            onCancel={() => setWorkbookStatusModal(false)}
            onOk={() => setWorkbookStatusModal(false)}
          >
            <SingleLineText
              name="Name"
              layout="horizontal"
              value={workbookStatus}
              onChange={(val) => setWorkbookStatus(val)}
            />
            <Button type="primary" style={{ width: '100%' }} size="large" onClick={_saveGroupData}>
              Save
            </Button>
          </Modal>
        )}

        {/* Workbook Progress Modal */}
        {workbookProgressModal && (
          <Modal
            visible={workbookProgressModal}
            title="Add Progress"
            footer={null}
            width={1800}
            style={{ maxWidth: '90%' }}
            onCancel={() => setWorkbookProgressModal(false)}
            onOk={() => setWorkbookProgressModal(false)}
          >
            <SingleLineText
              name="Name"
              layout="horizontal"
              value={workbookProgress}
              onChange={(val) => setWorkbookProgress(val)}
            />
            <Button
              type="primary"
              style={{ width: '100%' }}
              size="large"
              onClick={_saveProgressGroupData}
            >
              Save
            </Button>
          </Modal>
        )}
      </Modal>
    )
  }
)

export default PreviewInsurance
