import { Tooltip, Radio, Form, Space, Select } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { get } from 'lodash'
import {
  PageService,
  ProductService,
  QuestionnaireService,
} from 'services'
import { getAdviseForOptions } from 'services/advise_for'
import SingleSelectChart from 'Components/Charts/selectCharts'
import BarSingleSelectChart from 'Components/Charts/barSingleSelect'

const { Option } = Select

const SingleSelect = (props) => {
  const preview = document.URL.includes('preview')
  const [options, setOptions] = useState([])
  const [first, setFirst] = useState(true)
  const [mount, setMount] = useState(true)
  const [value, setValue] = useState('')
  const option_data = useSelector((state) => state.entityReducer)
  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity = params.get('entity')
    ? params.get('entity')
    : preview && previewUser?.details
      ? previewUser?.details?.id
      : user?.details?.id
  const business = preview ? previewUser?.details?.business : user?.details?.business

  // If the component's data_source_for is "business", override entity with business ID.
  let _entity = props.data_source_for === 'business' ? business : entity

  useEffect(() => {
    if (props.options_type && first) {
      setFirst(false)
      if (props.options_type === 'advise_for') {
        getAdviseForOptions(entity).then((res) => {
          const optionsObj = res?.data?.data || {}
          const objKeys = Object.keys(optionsObj)
          let opts = objKeys.map((item) => ({
            value: item,
            name: optionsObj[item],
          }))
          setOptions(opts)
        })
      } else if (props.options_type === 'user') {
        setOptions([
          { value: '', name: '' },
          ...option_data.user.map((item) => ({
            value: item.id,
            name: item.name,
          })),
        ])
      } else if (props.options_type === 'client') {
        setOptions([
          { value: '', name: '' },
          ...option_data.client.map((item) => ({
            value: item.id,
            name: item.name,
          })),
        ])
      } else if (props.options_type === 'business') {
        setOptions([
          { value: '', name: '' },
          ...option_data.business.map((item) => ({
            value: item.id,
            name: item.business_name,
          })),
        ])
      } else if (props.options_type === 'group') {
        if (props.id === '82da920d-f193-4636-8cb4-3883ef9ec2b0') {
          _entity = business
        }
        PageService.getGroupDropdown({ entity: _entity, field: props.id }).then((res) => {
          setOptions([{ value: '', name: '' }, ...get(res, 'data.data', [])])
        })
      } else if (props.options_type === 'form') {
        PageService.getFormDropdown({ entity: _entity, field: props.id }).then((res) => {
          setOptions([{ value: '', name: '' }, ...get(res, 'data.data', [])])
        })
      } else if (props.options_type === 'score_questionnaire') {
        get_question_data(true)
      } else if (props.options_type === 'non_score_questionnaire') {
        get_question_data(false)
      } else if (props.options_type === 'investment_option') {
        getProviders(business, false)
      }
    }
  }, [props, entity, _entity])

  useEffect(() => {
    if (!first && props.dependantValue && props.dependsOn) {
      setValue(props.filtering.values[props.dependantValue].defaultValue)
      props.onChange(props.filtering.values[props.dependantValue].defaultValue)
    }
  }, [props.dependantValue])
  useEffect(() => {
    if (mount && props.value) {
      setMount(false)
    }
    setValue(props.value)
  }, [props.value])

  useEffect(() => {
    if (props?.options_type == 'custom') {
      setOptions([
        { value: '', name: '' },
        ...(props.dependsOn && props.dependantValue
          ? props.filtering.values[props.dependantValue].options
          : props.options),
      ])
    }
  }, [props, props.dependantValue])

  const get_question_data = async (score) => {
    const res = await QuestionnaireService.getQuestionnaires()
    const data_ques = get(res, 'data.data', [])
      .filter((item) => item.show_score === score)
      .map((item) => ({
        name: item.display_name || item.name,
        value: item.id,
      }))
    setOptions(data_ques)
  }

  const getProviders = (business, is_for_investment) => {
    ProductService.getProvider({ business, is_for_investment }).then((res) => {
      const providers = res?.data?.data || []
      setOptions(
        providers.map((item) => ({
          value: item.id,
          name: item.subject,
        }))
      )
    })
  }

  return (
    <Form.Item>
      {/* ========================
          DISPLAY = "dropdown"
          ======================== */}
      {props.display === 'dropdown' &&
        (props.layout === 'horizontal' ? (
          <div
            className={props.isGroup ? 'form_field center_flex' : 'form_field inline_field'}
            style={{ width: '100%' }}
          >
            {/* Conditionally skip label if exclude_label === true */}
            {!props.exclude_label && (
              <label style={!props.isGroup ? { width: '20%' } : null}>
                {props.displayName ? props.displayName : props.name}{' '}
                {props.tooltip && (
                  <Tooltip title={props.tooltip}>
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                )}{' '}
                {props.isRequired && <span className="red">*</span>}
              </label>
            )}
            <div style={{ display: 'flex', flex: 1 }}>
              <Select
                style={{ width: props.width ? `${props.width}%` : '100%' }}
                placeholder="Please select"
                disabled={props.isReadOnly}
                value={value || props.selectedOptions}
                onChange={(val) => {
                  props.onChange(val)
                  setValue(val)
                }}
              >
                {options?.map?.((i, index) => (
                  <Option key={index} value={i.value}>
                    {i.name} {props.showValues ? i.value : null}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        ) : (
          // Vertical layout
          <div className="form_field" style={{ width: '100%' }}>
            {/* Conditionally skip label if exclude_label === true */}
            {!props.exclude_label && (
              <label style={{ display: 'block' }}>
                {props.displayName ? props.displayName : props.name}{' '}
                {props.tooltip && (
                  <Tooltip title={props.tooltip}>
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                )}{' '}
                {props.isRequired && <span className="red">*</span>}
              </label>
            )}
            <div style={{ display: 'flex', flex: 1 }}>
              <Select
                style={{ width: props.width ? `${props.width}%` : '100%' }}
                placeholder="Please select"
                disabled={props.isReadOnly}
                value={value || props.selectedOptions}
                onChange={(val) => {
                  props.onChange(val)
                  setValue(val)
                }}
              >
                {options?.map?.((i, index) => (
                  <Option key={index} value={i.value}>
                    {i.name} {props.showValues ? i.value : null}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        ))}

      {/* ========================
          DISPLAY = "button"
          ======================== */}
      {props.display === 'button' &&
        (props.layout === 'horizontal' ? (
          <div
            className={props.isGroup ? 'form_field center_flex' : 'form_field inline_field'}
            style={{ width: '100%' }}
          >
            {/* Conditionally skip label if exclude_label === true */}
            {!props.exclude_label && (
              <label style={!props.isGroup ? { width: '20%' } : null}>
                {props.displayName ? props.displayName : props.name}{' '}
                {props.tooltip && (
                  <Tooltip title={props.tooltip}>
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                )}{' '}
                {props.isRequired && <span className="red">*</span>}
              </label>
            )}
            <div>
              <Radio.Group
                disabled={props.isReadOnly}
                value={value || props.selectedOptions}
                onChange={(e) => {
                  setValue(e.target.value)
                  props.onChange(e.target.value)
                }}
              >
                <Space direction="horizontal">
                  {options
                    ?.filter((item) => item.name)
                    ?.map?.((i, index) => (
                      <Radio key={index} value={i.value}>
                        {i.name} {props.showValues ? i.value : null}
                      </Radio>
                    ))}
                </Space>
              </Radio.Group>
            </div>
          </div>
        ) : (
          // Vertical layout
          <div className="form_field" style={{ width: '100%' }}>
            {/* Conditionally skip label if exclude_label === true */}
            {!props.exclude_label && (
              <label style={{ marginBottom: 10, display: 'block' }}>
                {props.displayName ? props.displayName : props.name}{' '}
                {props.tooltip && (
                  <Tooltip title={props.tooltip}>
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                )}{' '}
                {props.isRequired && <span className="red">*</span>}
              </label>
            )}
            <div>
              <Radio.Group
                disabled={props.isReadOnly}
                value={value || props.selectedOptions}
                onChange={(e) => {
                  setValue(e.target.value)
                  props.onChange(e.target.value)
                }}
              >
                <Space direction="vertical">
                  {options
                    ?.filter((item) => item.name)
                    ?.map?.((i, index) => (
                      <Radio key={index} value={i.value}>
                        {i.name} {props.showValues ? i.value : null}
                      </Radio>
                    ))}
                </Space>
              </Radio.Group>
            </div>
          </div>
        ))}

      {/* ========================
          DISPLAY = "pie", "donut", "bar"
          Charts & Visuals
          ======================== */}
      {props.display === 'pie' && (
        <SingleSelectChart chartType="pie" multi={false} dt={props.options} />
      )}
      {props.display === 'donut' && (
        <SingleSelectChart
          showScore={props.showScore}
          multi={false}
          chartType="doughnut"
          dt={props.options}
        />
      )}
      {props.display === 'bar' && (
        <BarSingleSelectChart
          showScore={props.showScore}
          multi={false}
          chartType="stackedBar100"
          dt={props.options}
        />
      )}
    </Form.Item>
  )
}

export default SingleSelect
